import styles from '../../styles/StartEarning.module.scss'
import * as gtag from '../../lib/gtag'
import StartEarningComponent from './homePageSmallComponents/StartEarningComponent';
import StartEarningArrow from './homePageSmallComponents/StartEarningArrow';
import { useState, useEffect } from 'react';
import { getPushURL, returnImageURL } from '../../utils/getURLs';
import Router from 'next/router';
import { LocaleVariables } from '../../constants/appConstants/Locales';

const handleClickOnBtn = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}

const StartEarning = ({ Ref, dataForHomePage, locale }: { Ref: any, dataForHomePage: any, locale: any }) => {
    const basePath = dataForHomePage;
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])
    return (
        <section ref={Ref} className={locale === LocaleVariables.ads_da ? styles["start-earning-ads"] : styles["start-earning"]} id={"section-start-earning"}>
            <div className={styles["start-earning-container"]}>
                <h2 className={styles["start-earning-header"]}>{basePath?.heading}</h2>
                <div className={styles["step-container"]}>
                    <StartEarningComponent id={1} imageLink={returnImageURL(basePath?.earningStep[0]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={62} imageWidth={62} imageAlt='icon house' heading={basePath?.earningStep[0]?.heading} text={basePath?.earningStep[0]?.description} locale={locale} />
                    <StartEarningArrow imageLink={returnImageURL(basePath?.arrowIcon?.data[0]?.attributes, screenWidth)} imageHeight={26} imageWidth={16} imageAlt='arrow' />
                    <StartEarningComponent id={2} imageLink={returnImageURL(basePath?.earningStep[1]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={62} imageWidth={72} imageAlt='icon house online' heading={basePath?.earningStep[1]?.heading} text={basePath?.earningStep[1]?.description} locale={locale} />
                    <StartEarningArrow imageLink={returnImageURL(basePath?.arrowIcon?.data[0]?.attributes, screenWidth)} imageHeight={26} imageWidth={16} imageAlt='arrow' />
                    <StartEarningComponent id={3} imageLink={returnImageURL(basePath?.earningStep[2]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={62} imageWidth={66} imageAlt='icon income' heading={basePath?.earningStep[2]?.heading} text={basePath?.earningStep[2]?.description} locale={locale} />
                </div>
                <button onClick={() => {
                    handleClickOnBtn('holead:rental_calculation', 'calculate rental button', 'start earning');
                    if (typeof window !== 'undefined')
                        Router.push('/#section-lead-register', getPushURL(window.location.hostname, '/#section-lead-register'))
                }} className={styles["btn"]}>{basePath?.buttonTitle}</button>
            </div>

        </section>
    )
}

export default StartEarning;