import styles from '../../../styles/Partners.module.scss'
import Image from 'next/image';
import { useState, useEffect } from 'react';
import { getPushURL, returnImageURL } from '../../../utils/getURLs';
import Router from 'next/router';
import * as gtag from '../../../lib/gtag'
import { LocaleVariables } from '../../../constants/appConstants/Locales';

const handleClickOnBtn = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}

const Partners = ({ dataForHomePage, locale }: { dataForHomePage: any, locale: any }) => {
    const basePath = dataForHomePage;
    const basePathForImage = dataForHomePage.logos;
    const [screenWidth, setScreenWidth] = useState<number>(0);
    let row1 = [];
    let row2 = [];
    let row3 = [];
    for (let index = 0; index < 12; index++) {
        if (index < 4) row1.push(basePathForImage?.data[index]?.attributes);
        else if (index < 8 && index >= 4) row2.push(basePathForImage?.data[index]?.attributes);
        else row3.push(basePathForImage?.data[index]?.attributes);

    }
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])
    return (
        <section className={styles["partners"]}>
            <div className={styles["partners-container"]}>
                <div className={styles["row"]}>
                    <div className={styles["col-1"]}>
                        <h2 className={styles["partners-header"]}>{basePath?.heading}</h2>
                        <p className={locale === LocaleVariables.ads_da ? styles["partners-header-text-ads"] : styles["partners-header-text"]}>{basePath?.subHeading}</p>
                        <div className={styles["partners-logo-container"]}>
                            <div className={styles["partner-logo-row-mb"]}>
                                {row1.map((value: any, index: number) => {
                                    return (
                                        <div key={index} className={styles["partner-logo"]} role={`role-${index + 1}`}>
                                            <Image src={returnImageURL(value, screenWidth)} width={100} height={100} alt={value?.alternativeText} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles["partner-logo-row-mb"]}>
                                {row2.map((value: any, index: number) => {
                                    return (
                                        <div key={index} className={styles["partner-logo"]} role={`role-${index + 5}`}>
                                            <Image src={returnImageURL(value, screenWidth)} width={100} height={100} alt={value.alternativeText} />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={styles["partner-logo-row"]}>
                                {row3.map((value: any, index: number) => {
                                    return (
                                        <div key={index} className={styles["partner-logo"]} role={`role-${index + 9}`}>
                                            <Image src={returnImageURL(value, screenWidth)} width={100} height={100} alt={value?.alternativeText} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <button onClick={() => {
                            handleClickOnBtn('holead:rental_calculation', 'calculate rental button', 'partners');
                            if (typeof window !== 'undefined')
                                Router.push('/#section-lead-register', getPushURL(window.location.hostname, '/#section-lead-register'))
                        }} className={styles["btn"]}>{basePath?.buttonTitle}</button>
                    </div>
                    <div className={styles["col-2"]}>
                        <div className={styles["image"]}>
                            <Image width={826} height={706} layout="fixed" src={returnImageURL(basePathForImage?.data[12]?.attributes, screenWidth)} alt={basePathForImage?.data[12]?.attributes?.alternativeText} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Partners;

