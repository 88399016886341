import { LocaleVariables } from '../../../constants/appConstants/Locales';
import styles from '../../../styles/Home.module.scss';
import Question from './Question';

interface ListedQuestionProps {
    Ref: any;
    dataForHomePage: any;
    locale: any;
}
const ListedQuestions = ({ Ref, dataForHomePage, locale }: ListedQuestionProps) => {
    const basePath = dataForHomePage;
    return (
        <section ref={Ref} className={locale === LocaleVariables.ads_da ? styles["faqSection-ads"] : styles.faqSection} id={"section-qna"}>
            <div className={styles.container}>
                <h2 className={styles.faqH2}>{basePath?.heading}</h2>
                <div className={styles.faqRow}>
                    {
                        basePath?.frequentlyAskedQuestion.map((question: { question: string; answer: string; }) => (
                            <Question question={question?.question} answer={question?.answer} key={question?.question} locale={locale} />
                        ))
                    }
                </div>
            </div>
        </section>
    );
}

export default ListedQuestions;