import styles from '../../../styles/Home.module.scss';
import { useState } from "react";
import ReactMarkdown from 'react-markdown';
import * as gtag from '../../../lib/gtag'
import LinkRenderer from '../../markdownComponents/LinkRenderer';
import { LocaleVariables } from '../../../constants/appConstants/Locales';

interface QuestionProps {
    question: string;
    answer: string;
    locale: any;
}
const handleGoogleAnalytics = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}
const Question = ({ question, answer, locale }: QuestionProps) => {
    const [isActive, setActive] = useState<boolean>(false);
    const handleClick = () => {
        handleGoogleAnalytics("holead:landing page", "frequently asked questions", isActive === true ? "close" : "open")
        setActive(!isActive);
    };
    return (
        <div className={styles.faqQuestionsCont}>
            <div className={locale === LocaleVariables.ads_da ? styles["faqCollapseContainer-ads"] : styles["faqCollapseContainer"]}>
                <div className={styles.faqCollapseHeader} onClick={handleClick} data-testid="collapse-btn">
                    <span className={isActive ? (locale === LocaleVariables.ads_da ? styles["iconChevronCollapsed-ads"] : styles["iconChevronCollapsed"]) : (locale === LocaleVariables.ads_da ? styles["iconChevron-ads"] : styles["iconChevron"])} data-testid="collapse-icon"></span>
                    {question}
                </div>
                <div className={isActive ? styles.faqCollapsedContentCol : styles.faqCollapsedContent}>
                    <ReactMarkdown components={{ a: LinkRenderer }}>{answer}</ReactMarkdown>
                </div>
            </div>
        </div>
    );
}

export default Question;