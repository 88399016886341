import { LocaleVariables } from "../constants/appConstants/Locales";

const getZipCode = (address: any) => {
    const sizeOfArray = Object.keys(address).length;
    for (let index = 0; index < sizeOfArray; index++) {
        for (let j = 0; j < address[index].types.length; j++) {
            if (address[index].types[j] === 'postal_code') {
                return address[index].long_name;
            }
        }

    }
    return -1;
}

const getCountry = (address: any) => {
    const sizeOfArray = Object.keys(address).length;
    for (let index = 0; index < sizeOfArray; index++) {
        for (let j = 0; j < address[index].types.length; j++) {
            if (address[index].types[j] === 'country') {
                return address[index].short_name;
            }
        }
    }
    return -1;
}
const handleExPhoneNumber = (data: any) => {
    var exNum = '+' + data.dialCode.toString();
    for (let i = data.dialCode.toString().length + 1; i < data.format.length; i++) {
        if (data.format[i] === '.') {
            exNum += '8';
        } else {
            exNum += data.format[i];
        }
    }
    return exNum;
}
const handlePhoneNumber=(locale:any)=>{
    if(locale===LocaleVariables.se){
        return '+46 (888) 888-888';
    }else if(locale===LocaleVariables.no){
        return '+47 888 88 888';
    }else{
        return '+45 88 88 88 88';
    }
}

export { getZipCode, getCountry, handleExPhoneNumber ,handlePhoneNumber}