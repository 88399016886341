import styles from '../../styles/Home.module.scss'
import Image from 'next/image';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { useEffect, useState } from 'react';
import { usePlacesWidget } from "react-google-autocomplete";
import Router from 'next/router';
import * as gtag from '../../lib/gtag'
import * as fbq from '../../lib/fbq'
import { getSpaceValidation, handleEmailValidation, handlePhoneNumberValidation } from "../../utils/validationUtil";
import { useAppDispatch } from '../../app/hooks';
import {
    assignFullName, assignEmail, assignPrivatePool,
    assignAccoId, assignOwnerId, assignEstimation,
    assignBedrooms
} from '../../features/accoDetails/accoDetailsSlice';
import { Brand } from '../enums/EnumDefinition';
import ReactMarkdown from 'react-markdown'
import { getPushURL, returnImageURL } from '../../utils/getURLs';
import { RequiredFieldText, ValidAddress, ValidFullName, ValidEmailAddress, ValidPhoneNumber, AcceptTermsAndConditions } from '../../constants/appConstants/HoLeadErrors';
import { getParamByISO } from 'iso-country-currency';
import 'react-toastify/dist/ReactToastify.css';
import { BackendBaseURL } from '../../constants/appConstants/Urls';
import { LocaleVariables } from '../../constants/appConstants/Locales';
import LinkRenderer from '../markdownComponents/LinkRenderer';
import { unexpectedError, apiErrorHandling, processResponse } from '../../utils/errors';
import { GET, googleAPI, POST } from '../../apiCall/api';
import { getCountry, getZipCode, handleExPhoneNumber, handlePhoneNumber } from '../../utils/handleInputChange';
import { localWiseBaseURL } from '../../utils/ternaryConditionUtil';

const BASE_HOST = BackendBaseURL.BACKEND_BASE_URL;

const handleGoogleAnalytics = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}
const handleFaceBookPixel = (eventName: string, category: string, action: string, label: string) => {
    fbq.event({
        eventName: eventName,
        category: category,
        action: action,
        label: label,
    });
}

let yearBuilt: any[] = [];
let yearRenovation: any[] = [];
let numberOfBedroomsOptions: string[] = [];

const LeadRegister = ({ Ref, stepNumber, dataForHomePage, locale }: { Ref: any, stepNumber: number, dataForHomePage: any, locale?: any }) => {

    const basePathStep1 = dataForHomePage?.firstStep;
    const basePathStep2 = dataForHomePage?.secondStep;
    const basePath = dataForHomePage;

    const dispatch = useAppDispatch();

    // For Input
    const [formmatedAddress, setFormmatedAddress] = useState('');
    const [addressData, setAddressData] = useState<object>();
    const [addressLatLonData, setAddressLatLonData] = useState({ lat: 0, lng: 0 });
    const [bedRooms, setBedRooms] = useState('');
    const [rennovationYear, setRennovationYear] = useState('');
    const [builtYear, setBuiltYear] = useState('') as any;
    const [privatePool, setPrivatePool] = useState<boolean>();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumberValue, setPhoneNumberValue] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const [phoneNumberFormat, setPhoneNumberFormat] = useState('');
    const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
    const [phoneDialCode, setPhoneDialCode] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [acceptAgreement, setAcceptAgreement] = useState<boolean>(false);
    const [country, setCountry] = useState('');
    const [currency, setCurrency] = useState('');
    const [exPhoneNumber, setExPhoneNumber] = useState(handlePhoneNumber(locale));

    // errors
    const [showErrorAddress, setShowErrorAddress] = useState<boolean>(false);
    const [showErrorZipCode, setShowErrorZipcode] = useState<boolean>(false);
    const [showErrorBedroom, setShowErrorBedroom] = useState<boolean>(false);
    const [showErrorBuiltYear, setShowErrorBuiltYear] = useState<boolean>(false);
    const [showErrorRenoYear, setShowErrorRenoYear] = useState<boolean>(false);
    const [showErrorPool, setShowErrorPool] = useState<boolean>(false);
    const [showErrorFullName, setShowErrorFullName] = useState<boolean>(false);
    const [showErrorEmail, setShowErrorEmail] = useState<boolean>(false);
    const [showErrorPhone, setShowErrorPhone] = useState<boolean>(false);
    const [showErrorAgreement, setShowErrorAgreement] = useState<boolean>(false);

    const [tooltipVisibility, setTooltipVisibility] = useState(true);
    const [renovationButtonVisibility, setRenovationButtonVisibility] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    var currentYear = new Date().getFullYear();

    const bedRoomsInNumber = parseInt(bedRooms);
    const builtYearInNumber = parseInt(builtYear);
    const rennovationYearInNumber = (rennovationYear === '') ? 0 : parseInt(rennovationYear);
    const zipCodeWithOutSpace = zipCode.replace(/ /, "");
    const zipCodeInNumber = parseInt(zipCodeWithOutSpace);

    const [screenWidth, setScreenWidth] = useState<number>(0);
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])

    useEffect(() => {
        for (let index = 1; index < 51; index++) {
            if (index === 1) numberOfBedroomsOptions.push(index + ' ' + basePathStep1?.bedroom);
            else numberOfBedroomsOptions.push(index + ' ' + basePathStep1?.bedrooms);
        }
    }, [])

    useEffect(() => {

        yearBuilt = [];
        var year = currentYear;
        var diffBetweenYear = year - 1900;//this is difference between current year and 1900, so we can show the list of built year till 1900.
        for (let index = 0; index <= diffBetweenYear; index++) {
            yearBuilt.push(year);
            year = year - 1;
        }

        if (builtYear != '' && (currentYear - builtYear >= 15)) {
            setRenovationButtonVisibility(true);

        }
        else setRenovationButtonVisibility(false);

    }, [currentYear, builtYear]);

    const { ref } = usePlacesWidget<HTMLInputElement>({
        apiKey: 'AIzaSyC4lRVlngpcZVCA1O_hy59CLs1H3oHVAtY',
        onPlaceSelected: (place) => handleOnPlaceSelected(place),
        options: {
            types: ["address"],
            componentRestrictions: { country: locale === LocaleVariables.ads_da ? ["dk"] : ["dk", "no", "se"] },
        },
    });

    const handleOnPlaceSelected = (place: any) => {
        setShowErrorAddress(false);
        googleAPI(`https://maps.googleapis.com/maps/api/geocode/json?place_id=${place.place_id}&key=AIzaSyC4lRVlngpcZVCA1O_hy59CLs1H3oHVAtY`)
            .then(apiResponse => apiResponse.json())
            .then((response) => {
                if (response.status === 'OK') {
                    setAddressData(response.results[0]);
                    setAddressLatLonData(response.results[0].geometry.location);
                    setFormmatedAddress(response.results[0].formatted_address);
                    handleZipCode(response.results[0].address_components);
                    handleCountryWithCurrency(response.results[0].address_components);
                }
            })
            .catch(err => {
                console.log(err)
                unexpectedError(locale);
            })
    }

    const handleZipCode = (address: any) => {
        const postalCode = getZipCode(address);
        if (postalCode !== -1) {
            setZipCode(postalCode);
            setShowErrorZipcode(false);
        }
        else {
            setZipCode('');
        }
    }

    const handleCountryWithCurrency = (address: any) => {
        const countryName = getCountry(address);
        setCountry((countryName)?.toLowerCase());
        const currencyUpperCase = getParamByISO(countryName, 'currency');
        setCurrency(currencyUpperCase?.toLowerCase());

    }

    const handleNumberOfBedRoomsChange = (e: { target: { value: string } }) => {
        setBedRooms(e.target.value);
        setShowErrorBedroom(false);
    }

    const handleYearRennovationOnChange = (e: { target: { value: string } }) => {
        setRennovationYear(e.target.value);
        setShowErrorRenoYear(false);
    }

    const handleBuiltYearOnChange = (e: { target: { value: string } }) => {
        setBuiltYear(e.target.value);
        setShowErrorBuiltYear(false);
        let yearR: any = e.target.value;
        yearRenovation = [];
        var year = currentYear;
        for (let index = 0; index < currentYear - yearR + 1; index++) {
            yearRenovation.push(year);
            year = year - 1;
        }
    }

    const handleFullNameOnChange = (e: { target: { value: string } }) => {
        const result = e.target.value.replace(/[^a-z|À-Ö|Ø-ö| |ø-ÿ]/gi, '');
        setFullName(result);
        setShowErrorFullName(false);
    }

    const handleEmailOnChange = (e: { target: { value: string } }) => {
        setEmail(e.target.value);
        setShowErrorEmail(false);
    }

    const handlePhoneNumberOnChange = (value: string, data: any, event: any, formattedValue: string) => {
        setExPhoneNumber(handleExPhoneNumber(data));
        setPhoneNumberFormat(data?.format);
        setPhoneNumberValue(value);
        setPhoneCountryCode(data.countryCode);
        setPhoneDialCode(data.dialCode);
        setFormattedPhoneNumber(formattedValue);
        setShowErrorPhone(false);
    }
    const handleAgreementOnChange = () => {
        if (acceptAgreement === false) {
            setAcceptAgreement(true);
            setShowErrorAgreement(false);
        } else {
            setAcceptAgreement(false);
            setShowErrorAgreement(true);
        }
    }

    const handleBtnOnClick = async (type: number) => {
        if (type === 1) {

            let errorStatus = false;
            if (formmatedAddress.trim() === '') {
                setShowErrorAddress(true);
                errorStatus = true;
            }
            if (zipCode.trim() === '') {
                setShowErrorZipcode(true);
                errorStatus = true;
            }
            if (bedRooms.trim() === '') {
                setShowErrorBedroom(true);
                errorStatus = true;
            } else {
                setShowErrorBedroom(false);
            }
            if (builtYear.trim() === '') {
                setShowErrorBuiltYear(true);
                errorStatus = true;
            } else {
                setShowErrorBuiltYear(false);
            }
            if (renovationButtonVisibility && rennovationYear.trim() === '') {
                setShowErrorRenoYear(true);
                errorStatus = true;
            } else {
                setShowErrorRenoYear(false);
            }
            if (privatePool === undefined) {
                setShowErrorPool(true);
                errorStatus = true;
            } else {
                setShowErrorPool(false);
            }

            if (errorStatus === false) {
                setLoading1(true);
                dispatch(assignPrivatePool(privatePool || false)); //assign private pool value to redux variable
                dispatch(assignBedrooms(bedRooms)); //assign bedrooms value to redux variable
                if (typeof window !== 'undefined')
                    window.sessionStorage.setItem('homepagestep1', JSON.stringify({
                        formmatedAddress,
                        addressLatLonData,
                        bedRoomsInNumber,
                        builtYearInNumber,
                        rennovationYearInNumber,
                        zipCodeInNumber,
                        zipCode: zipCode === "" ? null : zipCode,
                        privatePool,
                        addressData,
                        country,
                        currency
                    }))

                handleGoogleAnalytics('holead:rental_calculation', 'calculate rental button clicked', 'property information');
                handleFaceBookPixel('Contact', 'holead:rental_calculation', 'calculate rental button clicked', 'property information (step1)')
                if (typeof window !== 'undefined')
                    Router.push('/step-2', getPushURL(window.location.hostname, '/step-2'));
            }
        }
        else {
            let errorStatus = false;
            if (fullName.trim() === '' || getSpaceValidation(fullName) === false) {
                setShowErrorFullName(true);
                errorStatus = true;
            } else {
                setShowErrorFullName(false);
            }
            if (email.trim() === '' || !handleEmailValidation(email)) {
                setShowErrorEmail(true);
                errorStatus = true;
            } else {
                setShowErrorEmail(false);
            }
            if (phoneNumberValue.trim() === '' || !handlePhoneNumberValidation(formattedPhoneNumber, phoneNumberFormat)) {
                setShowErrorPhone(true);
                errorStatus = true;
            } else {
                setShowErrorPhone(false);
            }
            if (acceptAgreement === false) {
                setShowErrorAgreement(true);
                errorStatus = true;
            } else {
                setShowErrorAgreement(false);
            }

            if (errorStatus === false) {
                setLoading2(true);
                dispatch(assignFullName(fullName)); //assign full name value to redux variable
                dispatch(assignEmail(email)); //assign email value to redux variable
                if (typeof window !== 'undefined') {
                    const step1Data = JSON.parse(window.sessionStorage.getItem('homepagestep1') || '{}');

                    const dataObjectForValuationRequest = {
                        "lat": step1Data.addressLatLonData.lat,
                        "country": step1Data?.country,
                        "brand": Brand.dc,
                        "currency": step1Data?.currency,
                        "long": step1Data.addressLatLonData.lng,
                        "no_bedrooms": step1Data.bedRoomsInNumber,
                        "build_year": step1Data.builtYearInNumber,
                        "renovation_year": step1Data.rennovationYearInNumber === 0 ? step1Data.builtYearInNumber : step1Data.rennovationYearInNumber,
                        "indoor_pool": step1Data.privatePool,
                    }
                    window.sessionStorage.setItem('valuationRequestPayload', JSON.stringify(dataObjectForValuationRequest));
                    // handle session login request
                    await GET(`${BASE_HOST}/api/session/login?userMail=${email}`)
                        // .then((res) => res.json())
                        .then((response) => {
                            if (response.status === 200) {
                                window.sessionStorage.setItem('sessionId', JSON.stringify({
                                    "sessionId": response.headers.get('JSESSIONID')
                                }));
                                window.sessionStorage.setItem('csrfToken', JSON.stringify({
                                    "csrfToken": response.headers.get('x-csrf-token')
                                }));
                            } else {
                                window.sessionStorage.clear();
                                Router.push('/', getPushURL(window.location.hostname, '/'));
                            }
                        })
                    await handleValuationRequest(dataObjectForValuationRequest);
                    handleGoogleAnalytics('holead:rental_calculation', 'calculate rental button clicked', 'contact details');
                    handleFaceBookPixel('Lead', 'holead:rental_calculation', 'calculate rental button clicked', 'property information (step2)');
                    const step2Data = JSON.parse(window.sessionStorage.getItem('homepagestep2') || '{}');
                    if (Object.keys(step2Data).length === 0)
                        window.sessionStorage.clear();
                    else
                        Router.push('/form/' + 1, getPushURL(window.location.hostname, '/form/1'));
                }
            }
        }
    }

    const handleValuationRequest = async (payload: any) => {
        if (typeof window !== 'undefined') {
            let csrfToken = JSON.parse(window.sessionStorage.getItem('csrfToken') || '');
            let sessionId = JSON.parse(window.sessionStorage.getItem('sessionId') || '');
            let headers = {
                'Content-type': 'application/json; charset=UTF-8',
                'Content-Encoding': 'gzip',
                'x-csrf-token': csrfToken?.csrfToken,
                'JSESSIONID': sessionId?.sessionId
            };
            await POST(`${BASE_HOST}/api/lead-data/estimate-valuation`, payload, headers)
                .then((apiResponse) => apiErrorHandling(apiResponse, locale))
                .then(correctApiResponse => correctApiResponse.json())
                .then(response => {
                    dispatch(assignEstimation(response['data'].estimate));
                    return response['data'].estimate;
                })
                .then(async (estimate_val) => {
                    const step1Data = JSON.parse(window.sessionStorage.getItem('homepagestep1') || '{}');
                    const utmParams = JSON.parse(window.sessionStorage.getItem('utmParams') || '{}');
                    const dataForInsertHouse = {
                        "acco_address": step1Data.formmatedAddress,
                        "lat": step1Data.addressLatLonData.lat,
                        "lon": step1Data.addressLatLonData.lng,
                        "acco_room": step1Data.bedRoomsInNumber,
                        "acco_pool": step1Data.privatePool === true ? 1 : 0,
                        "acco_build_year": step1Data.builtYearInNumber,
                        "acco_renovation_year": step1Data.rennovationYearInNumber === 0 ? step1Data.builtYearInNumber : step1Data.rennovationYearInNumber,
                        "ho_name": fullName,
                        "ho_email": email,
                        "ho_phone": '+' + phoneNumberValue,
                        "acco_zip_code": step1Data.zipCodeInNumber,
                        "acco_zip_code_string": step1Data.zipCode,
                        "valuation": estimate_val,
                        "currency": step1Data?.currency,
                        "country": step1Data?.country,
                        "brand": locale === LocaleVariables.ads_da ? Brand.ads_da : Brand.dc,
                        "acco-location-search-data": step1Data.addressData,
                        "extra": {
                            "isReferral": utmParams?.is_referral,
                            "utmSource": utmParams?.utmSource,
                            "utmCampaign": utmParams?.utmCampaign,
                            "utmMedium": utmParams?.utmMedium,
                            "utmTerm": utmParams?.utmTerm,
                            "referral-source": utmParams?.referral_source,
                            "referral-code": utmParams?.referral_code,
                            "url": localWiseBaseURL(locale)
                        },
                    }
                    await POST(`${BASE_HOST}/api/lead-data/insert-house`, dataForInsertHouse, headers)
                        .then((apiResponse) => apiErrorHandling(apiResponse, locale))
                        .then(correctApiResponse => processResponse(correctApiResponse))
                        .then(response => {
                            if (!response['success'])
                                return;
                            dispatch(assignAccoId(response['data'].accoId));
                            dispatch(assignOwnerId(response['data'].ownerId));
                            window.sessionStorage.setItem('homepagestep2', JSON.stringify({
                                fullName,
                                email,
                                "phoneNumber": '+' + phoneNumberValue,
                                "ownerId": response['data'].ownerId,
                                "accoId": response['data'].accoId,
                            }))
                            window.sessionStorage.setItem('estimation_value', JSON.stringify({
                                "estimation": estimate_val,
                            }))
                        })
                        .catch(err => {
                            console.log(err);
                            unexpectedError(locale);
                        })
                })
                .catch(err => {
                    console.log(err);
                    unexpectedError(locale);
                })
        }
    }

    return (
        <div>
            <div ref={Ref} data-testid='lead-register-component' className={styles["leadRegister"]} id={"section-lead-register"}>
                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && <Image priority src={returnImageURL(basePath?.backgroundImage?.data[0]?.attributes, screenWidth)} objectPosition={'top'} layout="fill" objectFit="cover" quality={100} alt='dk-background' />}
                {locale === LocaleVariables.no && <Image priority src={returnImageURL(basePath?.backgroundImage?.data[0]?.attributes, screenWidth)} objectPosition={'top'} layout="fill" objectFit="cover" quality={100} alt='no-background' />}
                <div className={stepNumber === 1 ? styles["page-hero-content"] : styles["page-hero-content-overlay"]} >
                    <div className={styles["lead-register-container"]}>
                        <div className={styles["row"]}>
                            <div className={styles["col-1"]}>
                                {stepNumber === 1 && <h1 className={styles["lead-register-header"]}>{basePathStep1?.heading}</h1>}
                                {stepNumber === 2 && <h1 className={styles["lead-register-header"]}>{basePathStep2?.heading}</h1>}
                            </div>
                            <div className={styles["col-2"]}>
                                <form action="" className={locale === LocaleVariables.ads_da ? styles["form-leads-register-ads"] : styles["form-leads-register"]} id={"form-leads-register"} name={"form-leads-register"}>
                                    {/* <!-- Form step: 1 -->
                                <!-- wwwdk wwwno --> */}
                                    {stepNumber === 1 &&
                                        <><div data-testid='form-step-1' className={styles["leads-form-step"]} data-step={"1"}>
                                            <div className={styles["form-title"]}>
                                                <span className={locale === LocaleVariables.ads_da ? styles["form-header-ads"] : styles["form-header"]}>{basePathStep1?.subHeading}</span>
                                                <span className={locale === LocaleVariables.ads_da ? styles["form-badge-step-ads"] : styles["form-badge-step"]}>{basePathStep1?.stepNumber}</span>
                                            </div>

                                            <div className={styles["form-group"]}>
                                                <input
                                                    autoComplete='off'
                                                    name='acco-address'
                                                    ref={ref}
                                                    className={`${styles["pac-target-input"]} ${(showErrorAddress || showErrorZipCode) ? styles["form-control-error"] : styles["form-control"]}`}
                                                    id={"acco-location"}
                                                    placeholder={basePathStep1?.address}
                                                    onFocus={() => handleGoogleAnalytics("holead:property_information", "raw_query_address", "")}
                                                />
                                                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorAddress && <div className={styles["text-error"]} id={"text-error-acco-location"}>{RequiredFieldText.dk}</div>}
                                                {locale === LocaleVariables.no && showErrorAddress && <div className={styles["text-error"]} id={"text-error-acco-location"}>{RequiredFieldText.no}</div>}
                                                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorZipCode && <div className={styles["text-error"]} id={"text-error-acco-location"}>{ValidAddress.dk}</div>}
                                                {locale === LocaleVariables.no && showErrorZipCode && <div className={styles["text-error"]} id={"text-error-acco-location"}>{ValidAddress.no}</div>}
                                                <input type="hidden" name="acco-location-search-data" id="acco-location-search-data" value={JSON.stringify(addressData)}></input>
                                                <input type="hidden" name="acco-location-search-latlon" id="acco-location-search-latlon" value={JSON.stringify(addressLatLonData)}></input>
                                            </div>

                                            <div className={styles["form-group"]}>
                                                <select onFocus={() => handleGoogleAnalytics("holead:property_information", "number of bedrooms", "")} data-testid='bedrooms' id="number-of-bedrooms" name="number-of-bedrooms" onChange={(e) => handleNumberOfBedRoomsChange(e)
                                                } className={`${styles["form-select"]} ${showErrorBedroom ? styles["form-control-error"] : styles["form-control"]}`} required>
                                                    <option value="">{basePathStep1?.numberOfBedrooms}</option>
                                                    {numberOfBedroomsOptions.map((value, index) => {
                                                        return <option key={index + 1} value={index + 1}>{value}</option>;
                                                    })}
                                                </select>
                                                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorBedroom && <div className={styles["text-error"]} id="text-error-number-of-bedrooms">{RequiredFieldText.dk}</div>}
                                                {locale === LocaleVariables.no && showErrorBedroom && <div className={styles["text-error"]} id="text-error-number-of-bedrooms">{RequiredFieldText.no}</div>}
                                            </div>

                                            <div className={styles["form-group"]}>
                                                <select onFocus={() => handleGoogleAnalytics("holead:property_information", "year built", "")} data-testid='built-year' id="year-built" name="year-built" onChange={(e) => handleBuiltYearOnChange(e)} className={`${styles["form-select"]} ${showErrorBuiltYear ? styles["form-control-error"] : styles["form-control"]}`} required>
                                                    <option value="">{basePathStep1?.buildYearQuestion}</option>
                                                    {yearBuilt.map((value) => {
                                                        return <option key={value} value={value}>{value}</option>;
                                                    })}
                                                </select>
                                                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorBuiltYear && <div className={styles["text-error"]} id="text-error-year-built">{RequiredFieldText.dk}</div>}
                                                {locale === LocaleVariables.no && showErrorBuiltYear && <div className={styles["text-error"]} id="text-error-year-built">{RequiredFieldText.no}</div>}
                                            </div>

                                            {renovationButtonVisibility && <div className={`${styles["form-group"]} ${styles["form-group-year-renovation"]}`}>
                                                <select onFocus={() => handleGoogleAnalytics("holead:property_information", "year renovation", "")} id="year-renovation" name="year-renovation" onChange={(e) => handleYearRennovationOnChange(e)} className={`${styles["form-select"]} ${showErrorRenoYear ? styles["form-control-error"] : styles["form-control"]}`} required>
                                                    <option value="">{basePathStep1?.renovationYearQuestion}</option>
                                                    {yearRenovation.map((value) => {
                                                        return <option key={value} value={value}>{value}</option>;
                                                    })}
                                                </select>
                                                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorRenoYear && <div className={styles["text-error"]} id="text-error-year-renovation">{RequiredFieldText.dk}</div>}
                                                {locale === LocaleVariables.no && showErrorRenoYear && <div className={styles["text-error"]} id="text-error-year-renovation">{RequiredFieldText.no}</div>}
                                            </div>}

                                            <div className={`${styles["form-group"]} ${locale === LocaleVariables.ads_da ? styles["form-group-private-pool-ads"] : styles["form-group-private-pool"]}`}>
                                                <div className={styles["private-pool"]}>
                                                    <div className={styles["private-pool-question"]}>{basePathStep1?.poolQuestion}</div>
                                                    <div className={styles["private-pool-options"]}>

                                                        <label data-testid='yes-btn' onFocus={() => handleGoogleAnalytics("holead:property_information", "swimming pool option selected", "Yes")} onClick={() => { setPrivatePool(true); setShowErrorPool(false); }} className={styles["radio-btn-container"]} >{basePathStep1?.yes}
                                                            <input data-testid='yes' type="radio" id="private-pool-1" name="private-pool" value="Yes" />
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>
                                                        <label data-testid='no-btn' onFocus={() => handleGoogleAnalytics("holead:property_information", "swimming pool option selected", "No")} onClick={() => { setPrivatePool(false); setShowErrorPool(false); }} className={styles["radio-btn-container"]} >{basePathStep1?.no}
                                                            <input data-testid='no' type="radio" id="private-pool-2" name="private-pool" value="No" />
                                                            <span className={styles["checkmark"]}></span>
                                                        </label>


                                                    </div>
                                                </div>
                                                {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorPool && <div className={styles["text-error"]} id="text-error-pool">{RequiredFieldText.dk}</div>}
                                                {locale === LocaleVariables.no && showErrorPool && <div className={styles["text-error"]} id="text-error-pool">{RequiredFieldText.no}</div>}
                                            </div>
                                        </div>
                                            <button data-testid='submit-step-1-btn' onClick={(e) => handleBtnOnClick(1)} type='button' className={loading1 ? styles["btn-loading"] : styles["btn"]} disabled={loading1}>
                                                {loading1 && <div className={styles["btn-loading-container"]}>
                                                    <div>{basePathStep1?.buttonLoadingTitle}</div>
                                                    <div className={styles["loader"]}></div>
                                                </div>}
                                                {!loading1 && <div>{basePathStep1?.buttonTitle}</div>}
                                            </button>
                                        </>
                                    }
                                    {/* <!-- Form step: 2 -->
                                <!-- wwwdk wwwno --> */}
                                    {stepNumber === 2 &&
                                        <>
                                            <div data-testid='form-step-2' className={styles["leads-form-step"]} data-step={"2"}>
                                                <div className={styles["form-title"]}>
                                                    <span className={locale === LocaleVariables.ads_da ? styles["form-header-ads"] : styles["form-header"]}>{basePathStep2?.subHeading}</span>
                                                    <span className={locale === LocaleVariables.ads_da ? styles["form-badge-step-ads"] : styles["form-badge-step"]}>{basePathStep2?.stepNumber}</span>
                                                </div>

                                                <div className={styles["form-group"]}>
                                                    <input onFocus={() => handleGoogleAnalytics("holead:property_information", "full name", "")} data-testid='name' type="text" className={showErrorFullName ? styles["form-control-error"] : styles["form-control"]} id={"ho-name"} name={"ho-name"} onChange={(e) => handleFullNameOnChange(e)} value={fullName} placeholder={basePathStep2?.fullName} maxLength={85} />
                                                    {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorFullName && <div className={styles["text-error"]} id="text-error-name">{ValidFullName.dk}</div>}
                                                    {locale === LocaleVariables.no && showErrorFullName && <div className={styles["text-error"]} id="text-error-name">{ValidFullName.no}</div>}
                                                </div>

                                                <div className={styles["form-group"]}>
                                                    <input onFocus={() => handleGoogleAnalytics("holead:property_information", "email", "")} data-testid='email' type="email" className={showErrorEmail ? styles["form-control-error"] : styles["form-control"]} id={"email"} name={"email"} onChange={(e) => handleEmailOnChange(e)} value={email} placeholder={basePathStep2?.email} />
                                                    {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorEmail && <div className={styles["text-error"]} id="text-error-email">{ValidEmailAddress.dk}</div>}
                                                    {locale === LocaleVariables.no && showErrorEmail && <div className={styles["text-error"]} id="text-error-email">{ValidEmailAddress.no}</div>}
                                                </div>

                                                <div className={`${styles["form-group"]} ${styles["phone-number-container"]}`}>
                                                    <PhoneInput
                                                        inputProps={{
                                                            name: 'phone',
                                                            autoFocus: false
                                                        }}
                                                        containerClass={styles["react-tel-input"]}
                                                        inputClass={showErrorPhone ? styles["form-control-phone-error"] : styles["form-control-phone"]}
                                                        placeholder={basePathStep2?.telephone}
                                                        country={locale === LocaleVariables.ads_da ? 'dk' : locale}
                                                        onFocus={() => {
                                                            setTooltipVisibility(false);
                                                            handleGoogleAnalytics("holead:property_information", "phone number", "");
                                                        }}
                                                        value={phoneNumberValue}
                                                        onChange={(value, data, event, formattedValue) => handlePhoneNumberOnChange(value, data, event, formattedValue)}>
                                                    </PhoneInput>

                                                    {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorPhone && <div className={styles["text-error"]} >{ValidPhoneNumber.dk} {exPhoneNumber}</div>}
                                                    {locale === LocaleVariables.no && showErrorPhone && <div className={styles["text-error"]} >{ValidPhoneNumber.no} {exPhoneNumber}</div>}

                                                    <input type="hidden" name="phone-country-code" id="phone-country-code" value={phoneCountryCode}></input>
                                                    <input type="hidden" name="phone-number" id="phone-number" value={formattedPhoneNumber}></input>
                                                    <input type="hidden" name="phone-dial-code" id="phone-dial-code" value={phoneDialCode}></input>
                                                    <input type="hidden" name="phone-number-valid" id="phone-number-valid" value=""></input>
                                                    <input type="hidden" name="phone-number-example" id="phone-number-example" value=""></input>

                                                    {tooltipVisibility && <div className={styles["fake-tooltip"]}>
                                                        <div className={styles["arrow"]}></div>
                                                        <div className={styles["fake-tooltip-inner"]}>
                                                            <div className={styles["fake-tooltip-text"]}>
                                                                <span className={styles["icon-info-gold"]}>
                                                                    <Image src={returnImageURL(basePathStep2?.infoIcon?.data[0]?.attributes, screenWidth)} width={22} height={22} alt={'info-gold-icon'} />
                                                                </span>
                                                                {basePathStep2?.tooltip}
                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>


                                                <div className={`${styles["form-group"]} ${styles["form-group-accept-terms"]}`} id="form-group-accept-terms">
                                                    <div className={styles["custom-switch"]}>
                                                        <label className={styles["switch"]}>
                                                            <input onFocus={() => handleGoogleAnalytics("holead:property_information", "accept terms&condition clicked", "")} type="checkbox" className={styles["switch-input"]} checked={acceptAgreement} onChange={handleAgreementOnChange} required />
                                                            <div className={`${styles["slider"]} ${styles["slider-round"]}`}></div>
                                                            <div className={styles["accept-terms-text"]}>
                                                                <ReactMarkdown components={{ a: LinkRenderer }}>{basePathStep2?.termsAndConditions}</ReactMarkdown>
                                                            </div>

                                                        </label>
                                                    </div>
                                                    <div className={styles["text-error"]} id="text-error-can-contact"></div>
                                                    {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && showErrorAgreement && <div className={styles["text-error"]} >{AcceptTermsAndConditions.dk}</div>}
                                                    {locale === LocaleVariables.no && showErrorAgreement && <div className={styles["text-error"]} >{AcceptTermsAndConditions.no}</div>}
                                                </div>
                                            </div>
                                            <button onClick={(e) => handleBtnOnClick(2)} type='button' className={loading2 ? styles["btn-loading"] : styles["btn"]} disabled={loading2}>
                                                {loading2 && <div className={styles["btn-loading-container"]}>
                                                    <div>{basePathStep2?.buttonLoadingTitle}</div>
                                                    <div className={styles["loader"]}></div>
                                                </div>}
                                                {!loading2 && <div>{basePathStep2?.buttonTitle}</div>}
                                            </button>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>


    );
}

export default LeadRegister;