import styles from '../../../styles/StartEarning.module.scss'
import Image from 'next/image';

const StartEarningArrow = ({ imageLink, imageHeight, imageWidth, imageAlt }: { imageLink: string, imageHeight: number, imageWidth: number, imageAlt: string }) => {
    return (
        <div className={styles["col-arrow"]}>
            <div className={styles["step-divider"]}>
                <div className={styles["image-icon-arrow"]}>
                    <Image src={imageLink} width={imageWidth} height={imageHeight} alt={imageAlt} />
                </div>
            </div>
        </div>
    )
}

export default StartEarningArrow;