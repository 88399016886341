import styles from '../../../styles/StartEarning.module.scss'
import Image from 'next/image';
import { LocaleVariables } from '../../../constants/appConstants/Locales';

const StartEarningComponent = ({ id, imageLink, imageHeight, imageWidth, imageAlt, heading, text, locale }: { id: number, imageLink: string, imageHeight: number, imageWidth: number, imageAlt: string, heading: string, text: string, locale: any }) => {
    return (
        <div className={styles["col-content"]}>
            <div className={styles["step"]}>
                <div className={styles["icon-container"]}>
                    <span className={styles["circle"]}>{id}</span>
                    <div className={styles["image"]}>
                        <Image src={imageLink} width={imageWidth} height={imageHeight} alt={imageAlt} />
                    </div>
                </div>
                <div className={styles["step-header"]}>{heading}</div>
                <div className={locale === LocaleVariables.ads_da ? styles["step-text-ads"] : styles["step-text"]}>{text}</div>
            </div>
        </div>
    )
}

export default StartEarningComponent;