import WhyUs from './lead/WhyUs';
import StartEarning from './lead/StartEarning';
import Navbar from "./Navbar";
import Footer from "./footer/Footer";
import LeadRegister from "./lead/LeadRegister";
import ListedQuestions from './lead/faq/ListedQuestions';
import Contact from './lead/Contact';
import { useInView } from 'react-intersection-observer';
import Statement from './lead/statementFromHomeOwner/Statement';
import BrandUsps from './lead/BrandUsps';
import { useEffect, useRef } from 'react';
import LeadRegisterSE from './lead/LeadRegisterSE';
import { LocaleVariables } from '../constants/appConstants/Locales';
import FooterADS from './footer/FooterADS';

const HomePageComponent = ({ stepNumber, dataForHomePage, locale }: { stepNumber: number, dataForHomePage: any, locale?: any }) => {

    const { ref: whyUsRef, inView: whyUsInView } = useInView({ threshold: 0.25, });
    const { ref: leadRegisterRef, inView: leadRegisterInView } = useInView({ threshold: 0.2, });
    const { ref: startEarningRef, inView: startEarningInView } = useInView({ threshold: 0.6, });
    const { ref: homeOwnerStatementRef, inView: homeOwnerStatementInView } = useInView({ threshold: 0.15, });
    const { ref: qnaRef, inView: qnaInView } = useInView({ threshold: 0.7, });
    const { ref: contactRef, inView: contactInView } = useInView({ threshold: 1, });

    const activeSection = {
        whyUsInView,
        leadRegisterInView,
        startEarningInView,
        homeOwnerStatementInView,
        qnaInView,
        contactInView,
    };

    const overlayRef = useRef<HTMLDivElement>(null!);

    useEffect(() => {
        if (stepNumber === 2) {
            overlayRef.current.style.height = document.body.clientHeight + "px";
        }
    });

    const basePath = dataForHomePage?.data[0]?.attributes;

    return (
        <div>
            <Navbar activeSection={activeSection} dataForHomePage={basePath?.navbar} locale={locale} />
            {locale != LocaleVariables.se && <LeadRegister Ref={leadRegisterRef} stepNumber={stepNumber} dataForHomePage={basePath?.leadRegister} locale={locale} />}
            {locale === LocaleVariables.se && <LeadRegisterSE Ref={leadRegisterRef} stepNumber={stepNumber} dataForHomePage={basePath?.leadRegister} />}
            <BrandUsps dataForHomePage={basePath?.brandSellingPoints} locale={locale} />
            <WhyUs Ref={whyUsRef} dataForHomePage={basePath?.whyUs} locale={locale} />
            <StartEarning Ref={startEarningRef} dataForHomePage={basePath?.startEarning} locale={locale} />
            {(locale === LocaleVariables.dk || locale === LocaleVariables.ads_da) && <Statement Ref={homeOwnerStatementRef} dataForHomePage={basePath} locale={locale} />}
            <ListedQuestions Ref={qnaRef} dataForHomePage={basePath?.faq} locale={locale} />
            <Contact Ref={contactRef} dataForHomePage={basePath.contactUs} locale={locale} />
            {locale === LocaleVariables.ads_da && <FooterADS dataForHomePage={basePath.adsFooter} locale={locale} />}
            <Footer dataForHomePage={basePath?.footer} locale={locale} />
            {stepNumber === 2 && <div ref={overlayRef} className='main-overlay'></div>}
        </div>
    )
}

export default HomePageComponent;
