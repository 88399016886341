import styles from '../../styles/BrandUsps.module.scss'
import BrandUspsComponent from './homePageSmallComponents/BrandUspsComponent';
import { useState, useEffect } from 'react';
import { returnImageURL } from '../../utils/getURLs';
import { LocaleVariables } from '../../constants/appConstants/Locales';

const BrandUsps = ({ dataForHomePage, locale }: { dataForHomePage: any, locale: any }) => {
    const basePath = dataForHomePage;
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])
    return (
        <div data-testid='brand-usp-component' className={locale === LocaleVariables.ads_da ? styles["brand-usps-ads"] : styles["brand-usps"]}>
            <div className={styles["brand-usps-container"]}>
                <div className={styles["usp"]}>
                    <BrandUspsComponent imageLink={returnImageURL(basePath[0]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={48} imageAlt='icon city' heading={basePath[0]?.stats} text={basePath[0]?.entity} />
                </div>
                <div className={styles["usp"]}>
                    <BrandUspsComponent imageLink={returnImageURL(basePath[1]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={49} imageAlt='icon house' heading={basePath[1]?.stats} text={basePath[1]?.entity} />
                </div>
                <div className={styles["usp"]}>
                    <BrandUspsComponent imageLink={returnImageURL(basePath[2]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={50} imageAlt='icon shield' heading={basePath[2]?.stats} text={basePath[2]?.entity} />
                </div>
                <div className={styles["usp-last"]}>
                    <BrandUspsComponent imageLink={returnImageURL(basePath[3]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={42} imageAlt='icon moon' heading={basePath[3]?.stats} text={basePath[3]?.entity} />
                </div>
            </div>
        </div>
    );
}

export default BrandUsps;