import styles from '../../../styles/HomeOwnerStatement.module.scss'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useState, useEffect } from 'react';
import Image from 'next/image';
import HomeOwnerStatementSlide from '../homePageSmallComponents/HomeOwnerStatementSlide';
import { returnImageURL } from '../../../utils/getURLs';
import * as gtag from '../../../lib/gtag'
import { LocaleVariables } from '../../../constants/appConstants/Locales';

const handleGoogleAnalytics = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}

const HomeOwnerStatement = ({ dataForHomePage, locale }: { dataForHomePage: any, locale: any }) => {
    const basePath = dataForHomePage;
    const [counter, setCounter] = useState(0);
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])

    return (
        <>
            <section className={locale === LocaleVariables.ads_da ? styles["statement-from-homeowner-ads"] : styles["statement-from-homeowner"]}>
                <div data-testid='hos-container' className={styles["statement-from-homeowner-container"]}>
                    <h2 className={styles["statement-from-homeowner-header"]}>{basePath?.heading}</h2>
                    <div className={styles["testimonial-container"]}>
                        <div style={{ position: 'relative' }} className={'carousel-testimonial'}>
                            <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={50}
                                isIntrinsicHeight
                                totalSlides={3}>
                                <span data-testid='back-btn' onClick={() => {
                                    if (counter > 0) {
                                        handleGoogleAnalytics("holead:landing page", "change_testimonial_slide", "slide" + counter)
                                        setCounter(counter - 1);
                                    }
                                }}>
                                    {counter > 0 && <ButtonBack className={styles["previous-button"]}>
                                        <div className={styles["flickity-button-icon"]}>
                                            <Image src={returnImageURL(basePath?.prevArrow?.data[0]?.attributes, screenWidth)} layout='fill' alt="back" />
                                        </div>
                                    </ButtonBack>}
                                </span>

                                <Slider>
                                    <Slide index={0}>
                                        <HomeOwnerStatementSlide imageLink={returnImageURL(basePath?.statements[0]?.homeowner?.data[0]?.attributes, screenWidth)} statement={basePath?.statements[0]?.statement} name={basePath?.statements[0]?.name} quotationMarkIcon={returnImageURL(basePath?.quotationMarks?.data[0]?.attributes, screenWidth)} />
                                    </Slide>
                                    <Slide index={1}>
                                        <HomeOwnerStatementSlide imageLink={returnImageURL(basePath?.statements[1]?.homeowner?.data[0]?.attributes, screenWidth)} statement={basePath?.statements[1]?.statement} name={basePath?.statements[1]?.name} quotationMarkIcon={returnImageURL(basePath?.quotationMarks?.data[0]?.attributes, screenWidth)} />
                                    </Slide>
                                    <Slide index={2}>
                                        <HomeOwnerStatementSlide imageLink={returnImageURL(basePath?.statements[2]?.homeowner?.data[0]?.attributes, screenWidth)} statement={basePath?.statements[2]?.statement} name={basePath?.statements[2]?.name} quotationMarkIcon={returnImageURL(basePath?.quotationMarks?.data[0]?.attributes, screenWidth)} />
                                    </Slide>
                                </Slider>
                                <div className={styles['dot-container']}>
                                    <span data-testid='dot-1' onClick={() => { setCounter(0); }}>
                                        <Dot slide={0} className={styles["dots"]}>
                                            <div data-testid='dot-1-style' className={counter === 0 ? styles["dot-selected"] : styles["dot"]}></div>
                                        </Dot>
                                    </span>
                                    <span data-testid='dot-2' onClick={() => { setCounter(1); }}>
                                        <Dot slide={1} className={styles["dots"]}>
                                            <div data-testid='dot-2-style' className={counter === 1 ? styles["dot-selected"] : styles["dot"]}></div>
                                        </Dot>
                                    </span>
                                    <span data-testid='dot-3' onClick={() => { setCounter(2); }}>
                                        <Dot slide={2} className={styles["dots"]}>
                                            <div data-testid='dot-3-style' className={counter === 2 ? styles["dot-selected"] : styles["dot"]}></div>
                                        </Dot>
                                    </span>
                                </div>
                                <span data-testid='next-btn' onClick={() => {
                                    if (counter < 2) {
                                        handleGoogleAnalytics("holead:landing page", "change_testimonial_slide", "slide" + (counter + 2))
                                        setCounter(counter + 1);
                                    }
                                }}>
                                    {counter < 2 && <ButtonNext className={styles["next-button"]}>
                                        <div className={styles["flickity-button-icon"]}>
                                            <Image src={returnImageURL(basePath?.nextArrow?.data[0]?.attributes, screenWidth)} layout='fill' alt="next" />
                                        </div>
                                    </ButtonNext>}
                                </span>

                            </CarouselProvider>
                        </div>
                    </div>
                </div>

                <style jsx>
                    {`
                    .carousel-cell {
                        width: 100%;
                        margin-right: 10px;
                    }

                `}
                </style>
            </section>
        </>
    )
}

export default HomeOwnerStatement;