import styles from '../../../styles/BrandUsps.module.scss'
import Image from 'next/image';

const BrandUspsComponent = ({ imageLink, imageHeight, imageWidth, imageAlt, heading, text }: { imageLink: string, imageHeight: number, imageWidth: number, imageAlt: string, heading: string, text: string }) => {
    return (
        <>
            <div className={styles["image"]}>
                <Image src={imageLink} width={imageWidth} height={imageHeight} alt={imageAlt} />
            </div>
            <div className={styles["usp-text"]} >
                <div className={styles["info-text"]}>{heading}</div>
                {text}
            </div>
        </>
    );
}

export default BrandUspsComponent;