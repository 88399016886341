import Partners from "./Partners";
import HomeOwnerStatement from "./HomeOwnerStatement";
import styles from '../../../styles/HomeOwnerStatement.module.scss'

const Statement = ({ Ref, dataForHomePage, locale }: { Ref: any, dataForHomePage: any, locale: any }) => {
    return (
        <section className={styles["homeowner-statement-and-partner-section"]} id={"section-statement-from-homeowner"} ref={Ref}  >
            <HomeOwnerStatement dataForHomePage={dataForHomePage?.homeownerStatements} locale={locale} />
            <Partners dataForHomePage={dataForHomePage?.partners} locale={locale} />
        </section>
    );
}

export default Statement;