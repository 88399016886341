import styles from "../../styles/HeaderFooter.module.scss"
import Image from 'next/image';

const AdsFooterAddress = ({ dataForHomePage, mobileViewHidden }: { dataForHomePage: any, mobileViewHidden: boolean }) => {
    const basePath = dataForHomePage;

    return (
        <div className={mobileViewHidden ? styles["ads-footer-address-hidden"] : styles["ads-footer-address"]}>
            <b>{basePath?.title}</b>
            <br />
            {basePath?.addressLine1}
            <br />
            {basePath?.addressLine2}
            <br />
            <br />
            <a className={styles["ads-footer-contact"]} href={`mailto:${basePath?.contactEmail}`}>{basePath?.contactEmail}</a>
            <br />
            {basePath?.contactNumber}
        </div>
    );
}

export default AdsFooterAddress;