import styles from '../../../styles/WhyUs.module.scss'
import Image from 'next/image';
import ReactMarkdown from 'react-markdown'
import LinkRenderer from '../../markdownComponents/LinkRenderer';
import { LocaleVariables } from '../../../constants/appConstants/Locales';

const WhyUsComponent = ({ imageLink, imageHeight, imageWidth, imageAlt, heading, text, locale }: { imageLink: string, imageHeight: number, imageWidth: number, imageAlt: string, heading: string, text: string, locale: any }) => {
    return (
        <div className={styles["cell"]}>
            <div className={styles["usp"]}>
                <div className={styles["image"]}>
                    <Image src={imageLink} width={imageWidth} height={imageHeight} alt={imageAlt} />
                </div>
                <div className={styles["usp-header"]}>{heading}</div>
                <div className={locale === LocaleVariables.ads_da ? styles["usp-text-ads"] : styles["usp-text"]}><ReactMarkdown components={{ a: LinkRenderer }}>{text}</ReactMarkdown></div>
            </div>
        </div>
    )
}
export default WhyUsComponent;