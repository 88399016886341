import styles from "../../styles/HeaderFooter.module.scss"
import Image from 'next/image';
import AdsFooterAddress from "./AdsFooterAddress";
import { useEffect, useState } from "react";
import { returnImageURL } from "../../utils/getURLs";

const FooterADS = ({ dataForHomePage, locale }: { dataForHomePage: any, locale?: any }) => {
    const basePath = dataForHomePage;
    const [screenWidth, setScreenWidth] = useState<number>(0);

    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])

    return (
        <div className={styles["footer-top"]} data-testid='ads-footer-component'>
            <div className={styles["ads-footer-container"]}>
                <div className={styles["ads-row"]}>
                    <div className={styles["ads-footer-title"]}>
                        <span className={styles["ads-footer-title-text"]}>{basePath?.title}</span>
                        <Image src={returnImageURL(basePath?.logo?.data[0]?.attributes, screenWidth)} width={160} height={90} alt="Admiral Strand logo" />
                    </div>
                </div>
                <div className={styles["ads-row"]}>
                    <AdsFooterAddress dataForHomePage={basePath?.contactInformation[0]} mobileViewHidden={false} />
                    <AdsFooterAddress dataForHomePage={basePath?.contactInformation[1]} mobileViewHidden={true} />
                    <AdsFooterAddress dataForHomePage={basePath?.contactInformation[2]} mobileViewHidden={true} />
                    <AdsFooterAddress dataForHomePage={basePath?.contactInformation[3]} mobileViewHidden={true} />
                </div>
            </div>
        </div>
    );
}

export default FooterADS;