export enum Currency {
    dkk = "dkk",
    sek = "sek",
    nok = "nok",
    eur = "eur",
    chf = "chf",
    gbp = "gbp",
    hrk = "hrk",
    pln = "pln",
    usd = "usd",
}

export enum Country {
    dk = "dk",
    se = "se",
    no = "no",
}

export enum Brand {
    dc = "dc",
    ads_da="ads",
}

export enum Availability {
    full_low_and_4_weeks_high = "full_low_and_4_weeks_high",
    four_weeks_high = "four_weeks_high",
    five_weeks_high = "five_weeks_high",
    full_year = "full_year",
    full_high_and_pre_post_season = "full_high_and_pre_post_season"
}

export enum WhenCanRent {
    less1m = 'less1m',
    btn1to3m = "btn1to3m",
    more3m = "more3m",
}

export enum DistanceTo {
    RESTAURANT = "RESTAURANT",
    GROCERY_STORE = "GROCERY_STORE",
    COASTLINE = "COASTLINE",
}