import Image from "next/image";
import { useEffect, useState } from "react";
import { LocaleVariables } from "../../constants/appConstants/Locales";
import styles from '../../styles/Home.module.scss'
import { returnImageURL } from "../../utils/getURLs";

const Contact = ({ Ref, dataForHomePage, locale }: { Ref: any, dataForHomePage: any, locale?: any }) => {

    const basePath = dataForHomePage;
    const basePathForEmailImage = dataForHomePage?.emailIcon?.data[0]?.attributes;
    const basePathForMessageImage = dataForHomePage?.messageIcon?.data[0]?.attributes;
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])

    const [availTextSe, setAvailTextSe] = useState('');
    const [availStatusSe, setAvailStatusSe] = useState('');
    const [availTextNo, setAvailTextNo] = useState('');
    const [availStatusNo, setAvailStatusNo] = useState('');
    const { utcToZonedTime } = require('date-fns-tz')
    useEffect(() => {
        let today = new Date();
        let utcDate = today.toUTCString();
        const date = new Date(utcDate)
        const zonedDate = utcToZonedTime(date, basePath?.timezone)

        let hours = zonedDate.getHours();
        let minutes = zonedDate.getMinutes();
        let current_week_day = zonedDate.getDay();
        if ((hours >= 8 && minutes >= 0) && (hours <= 16 && minutes <= 59) && (current_week_day >= 1 && current_week_day <= 5)) setAvailStatusSe('Open');
        else setAvailStatusSe('Close');

        if (availStatusSe === 'Open') {
            setAvailTextSe(basePath?.availabilityText + ' ' + basePath?.endTime)
        }
        else {
            // closed- open monday
            if (current_week_day === 0 || current_week_day === 6 || (current_week_day === 5 && hours >= 17 && minutes >= 0)) setAvailTextSe(basePath?.unavailabilityTextWeekend + ' ' + basePath?.startTime);
            // Closed-open tomorrow
            else if ((current_week_day >= 1 && current_week_day <= 4) && (hours >= 17 && minutes >= 0) && (hours <= 23 && minutes <= 59)) setAvailTextSe(basePath?.unavailabilityTextTomorrow + ' ' + basePath?.startTime);
            // closed-open today
            else setAvailTextSe(basePath?.unavailabilityTextToday + ' ' + basePath?.startTime);
        }

    }, [availStatusSe, basePath, utcToZonedTime])

    useEffect(() => {
        let today = new Date();
        let utcDate = today.toUTCString();
        const date = new Date(utcDate)
        const zonedDate = utcToZonedTime(date, basePath?.timezone)
        let hours = zonedDate.getHours();
        let minutes = zonedDate.getMinutes();
        let current_week_day = zonedDate.getDay();
        if ((hours >= 10 && minutes >= 0) && (hours <= 14 && minutes <= 59) && (current_week_day >= 1 && current_week_day <= 5)) setAvailStatusNo('Open');
        else setAvailStatusNo('Close');

        if (availStatusNo === 'Open') {
            setAvailTextNo(basePath?.availabilityText + ' ' + basePath?.endTime)
        }
        else {
            // closed- open monday
            if (current_week_day === 0 || current_week_day === 6 || (current_week_day === 5 && hours >= 15 && minutes >= 0)) setAvailTextNo(basePath?.unavailabilityTextWeekend + ' ' + basePath?.startTime);
            // closed-open tomorrow
            else if ((current_week_day >= 1 && current_week_day <= 4) && (hours >= 15 && minutes >= 0) && (hours <= 23 && minutes <= 59)) setAvailTextNo(basePath?.unavailabilityTextTomorrow + ' ' + basePath?.startTime);
            // closed-open today
            else setAvailTextNo(basePath?.unavailabilityTextToday + ' ' + basePath?.startTime);
        }

    }, [availStatusNo, basePath, utcToZonedTime])
    return (
        <section ref={Ref} className={locale === LocaleVariables.ads_da ? styles["contactUsSection-ads"] : styles.contactUsSection} id={"section-contactus"}>
            <div className={styles.container}>
                <h2 className={styles.contactUsH2}>{basePath?.heading}</h2>
                <p className={locale === LocaleVariables.ads_da ? styles["contactP-ads"] : styles.contactP}>{basePath?.subHeading}</p>
                <div className={styles.contactOption}>
                    <div className={styles.singleIconContainer}>
                        <div className={styles.contactMessage}><Image alt="icon message" src={returnImageURL(basePathForEmailImage, screenWidth)} width={41} height={41} /></div>
                        <div className={styles.contactDiv}>
                            {/* Don't need to be handled with Link */}
                            <a className={locale === LocaleVariables.ads_da ? styles["contactA-ads"] : styles.contactA} href={`tel:${basePath?.contactNumber}`}>{basePath?.contactNumber}</a>
                            {locale === LocaleVariables.se && <div className={styles.contactAvail}>
                                <span className={availStatusSe === 'Open' ? styles["greenAvailability"] : styles["redAvailability"]}></span>
                                {availTextSe}
                            </div>}
                            {locale === LocaleVariables.no && <div className={styles.contactAvail}>
                                <span className={availStatusNo === 'Open' ? styles["greenAvailability"] : styles["redAvailability"]}></span>
                                {availTextNo}
                            </div>}
                        </div>
                    </div>
                    <div className={styles.singleIconContainer}>
                        <div className={styles.contactEmail}><Image alt="icon email" src={returnImageURL(basePathForMessageImage, screenWidth)} width={41} height={41} /></div>
                        <div className={styles.contactDiv}>
                            <a className={locale === LocaleVariables.ads_da ? styles["contactA-ads"] : styles.contactA} href={`mailto:${basePath?.contactEmail}`}>{basePath?.contactEmail}</a>
                            <div className={locale === LocaleVariables.ads_da ? styles["contactAvail-ads"] : styles.contactAvail}>{basePath?.replyTime}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;