const googleAPI = (uri: string) => {
    return fetch(uri, {
        method: "GET",
        headers: {
            'Accept-Language': 'en',
        }
    })
}

const GET = async (uri: string) => {
    return await fetch(uri, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'Content-Encoding': 'gzip',
        },
        credentials: 'include'
    })
}

const POST = async (uri: string, data: any, headers: any) => {
    return await fetch(uri, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers,
        credentials: 'include'
    })
}

const PATCH = async (uri: string, data: any, headers: any) => {
    return await fetch(uri, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: headers,
        credentials: 'include'
    })
}

const PUT = async (uri: string, headers: any) => {
    return await fetch(uri, {
        method: 'PUT',
        headers: headers,
        credentials: 'include'
    })
}
export { googleAPI, GET, POST, PATCH, PUT }