import styles from '../../../styles/HomeOwnerStatement.module.scss'
import Image from 'next/image';

const HomeOwnerStatementSlide = ({ imageLink, statement, name, quotationMarkIcon }: { imageLink: string, statement: string, name: string, quotationMarkIcon: string }) => {
    return (
        <div className="carousel-cell">
            <div className={styles["row"]}>
                <div className={styles["col-1"]}>
                    <div className={styles["testimonial-img"]}>
                        <Image layout='fill' objectPosition={'left'} objectFit='contain' src={imageLink} alt='homeowner-photo' />
                    </div>
                </div>
                <div className={styles["col-2"]}>
                    <div className={styles["image"]}>
                        <Image src={quotationMarkIcon} width={38} height={31} alt="icon-quote" />
                    </div>
                    <div className={styles["testimonial-quote"]}>{statement}</div>
                    <div className={styles["testimonial-homeowner"]}>{name}</div>
                </div>
            </div>
        </div>
    )
}

export default HomeOwnerStatementSlide;