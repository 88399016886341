import styles from '../../styles/WhyUs.module.scss'
import * as gtag from '../../lib/gtag'
import WhyUsComponent from './homePageSmallComponents/WhyUsComponent';
import { useState, useEffect } from 'react';
import { getPushURL, returnImageURL } from '../../utils/getURLs';
import Router from 'next/router';
import { LocaleVariables } from '../../constants/appConstants/Locales';

const handleClickOnBtn = (category: string, action: string, label: string) => {
    gtag.event({
        category: category,
        action: action,
        label: label,
    });
}

const WhyUs = ({ Ref, dataForHomePage, locale }: { Ref: any, dataForHomePage: any, locale: any }) => {
    const basePath = dataForHomePage;
    const [screenWidth, setScreenWidth] = useState<number>(0);
    useEffect(() => {
        setScreenWidth(document.body.clientWidth);
    }, [])
    return (
        <section ref={Ref} className={locale == LocaleVariables.ads_da ? styles["whyUs-ads"] : styles["whyUs"]} id={"section-whyus"}>
            <div className={styles["whyUs-container"]}>
                <h2 className={styles["whyUs-header"]}>{basePath?.heading}</h2>
                <p className={locale === LocaleVariables.ads_da ? styles["whyUs-header-text-ads"] : styles["whyUs-header-text"]}>{basePath?.subHeading}</p>

                <div className={styles["renting-usps"]}>
                    <div className={styles["renting-usps-container"]}>
                        <WhyUsComponent imageLink={returnImageURL(basePath?.outreachPoints[0]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={51} imageAlt='icon-graph' heading={basePath?.outreachPoints[0]?.heading} text={basePath?.outreachPoints[0]?.description} locale={locale} />
                        <WhyUsComponent imageLink={returnImageURL(basePath?.outreachPoints[1]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={56} imageAlt='icon-income' heading={basePath?.outreachPoints[1]?.heading} text={basePath?.outreachPoints[1]?.description} locale={locale} />
                        <WhyUsComponent imageLink={returnImageURL(basePath?.outreachPoints[2]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={46} imageAlt='icon-city' heading={basePath?.outreachPoints[2]?.heading} text={basePath?.outreachPoints[2]?.description} locale={locale} />
                        <WhyUsComponent imageLink={returnImageURL(basePath?.outreachPoints[3]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={52} imageAlt='icon-booking-approve' heading={basePath?.outreachPoints[3]?.heading} text={basePath?.outreachPoints[3]?.description} locale={locale} />
                        <WhyUsComponent imageLink={returnImageURL(basePath?.outreachPoints[4]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={42} imageAlt='icon-diamond' heading={basePath?.outreachPoints[4]?.heading} text={basePath?.outreachPoints[4]?.description} locale={locale} />
                        <WhyUsComponent imageLink={returnImageURL(basePath?.outreachPoints[5]?.icon?.data[0]?.attributes, screenWidth)} imageHeight={50} imageWidth={69} imageAlt='icon-shield' heading={basePath?.outreachPoints[5]?.heading} text={basePath?.outreachPoints[5]?.description} locale={locale} />
                    </div>
                </div>
                <button onClick={() => {
                    handleClickOnBtn('holead:rental_calculation', 'calculate rental button', 'why us');
                    if (typeof window !== 'undefined')
                        Router.push('/#section-lead-register', getPushURL(window.location.hostname, '/#section-lead-register'))
                }} className={styles["btn"]}>{basePath?.buttonTitle}</button>
            </div>

        </section>
    )
}
export default WhyUs;